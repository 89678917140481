$light-theme-colors: (
  'app-background': #fff,
  'editor-line-number': rgba(27, 31, 35, 0.6),
  'editor-line-content': #000,
  'editor-line-content-a': #005cc5,
  'editor-line-content-i': #6a737d
);

$dark-theme-colors: (
  'app-background': #1e1e1e,
  'editor-line-number': rgba(228, 224, 220, 0.6),
  'editor-line-content': #e3e3e3,
  'editor-line-content-a': #9adafd,
  'editor-line-content-i': #7f7f7f
);

@mixin themify($property, $theme-key) {
  #{$property}: map-get($light-theme-colors, $theme-key);

  @media screen and (prefers-color-scheme: dark) {
    #{$property}: map-get($dark-theme-colors, $theme-key);
  }
}

.App {
  @include themify('background-color', 'app-background');
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.Editor {
  margin: auto;
  padding: 20px;
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  font-size: 12px;
  line-height: 16px;

  &__line {
    vertical-align: top;
  }

  &__lineNumber {
    @include themify('color', 'editor-line-number');
    width: 20px;
    padding-top: 1px;
    text-align: right;
  }
  
  &__lineContent {
    @include themify('color', 'editor-line-content');
    padding-left: 20px;
  
    a {
      @include themify('color', 'editor-line-content-a');
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  
    i {
      @include themify('color', 'editor-line-content-i');
    }
  }
}
